import website from '@/config/website'

export const tableOption = {
    border: true,
    stripe: true,
    searchMenuSpan: 6,
    align: 'center',
    menu:true,
    fit: true,
    addBtn: true,
    dialogWidth: '30%',
    cellBtn: true,
    dialogCloseBtn: false,
    column: [{
        label: '币种',
        prop: 'coin',
        span: 24,
        type: 'select',
        dicUrl: `${website.urls}/chain/chaincoininfo/page`,
        dicFormatter: (res)=>{
            const records = res.records
            const uniqueArr = records.filter((obj, index, self) =>
                index === self.findIndex(o => o.coin === obj.coin)
            );
            return uniqueArr
        },
        dicQuery: {current:1,size: 1000},
        props: {
          label: 'coin',
          value: 'coin',
          desc: 'chain',
        },
        search:true,
        filterable: true,
        dicMethod: 'get',
        cell: true,
        rules: [{
            required: true,
            message: "请选择币种",
            trigger: "blur"
        }]
    },{
        label: '价格',
        prop: 'price',
        width: 150,
        cell: true,
        placeholder: '请输入兑USDT的价格',
        tip: '1[输入币种] = [输入价格]USDT',
        span: 24,
        rules: [{
            required: true,
            message: "请输入价格",
            trigger: "blur"
        }]
    },{
        label: '创建时间',
        prop: 'createTime',
        search:true,
        searchType: 'datetimerange',
        // defaultTime:['00:00:00', '23:59:59'],
        display: false
    }, {
        label: '修改时间',
        prop: 'updateTime',
        searchType: 'datetimerange',
        // defaultTime:['00:00:00', '23:59:59'],
        search:true,
        display: false
    }, {
        label: '最后修改人',
        prop: 'updateBy',
        searchLabelWidth: 100,
        search:true,
        display: false
    }
],
}
