<template>
    <div>
        <avue-crud ref="crud" :option="tableOpt" :data="list" :page="page" @search-change="searchChange"
            v-model="form" :table-loading="listLoading" @row-save="saveFun" @row-update="rowUpdate" @row-del="rowDel"
            @size-change="sizeChange"
            @current-change="currentChange">   
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #updateTime="scope">
                {{turnTimeFun(scope.row.updateTime)}}
            </template>
        </avue-crud>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,reactive,getCurrentInstance } from 'vue'
    import { tableOption } from "@/const/crud/cryptocurrency/cpm";
    import apiList from '@/const/apiList'
    import { ElNotification,ElMessageBox } from 'element-plus'
    const { $t } = getCurrentInstance().proxy;
    import { turnTimeFun } from '@/utils/util.js'
    import { userStore } from '@/store/user'
    
    const list = ref([])
    const form = ref({})
    const listLoading = ref(false)
    const tableOpt = ref({});
    tableOpt.value = tableOption
    const page = reactive({
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
    })
    const tenantArray = ref([])
    const appArray = ref([])
    const chainList = ref([])
    const searchForm = ref([])

    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    // form.tenantId = userStore().getUserInfo[0].tenantId
   
    apiList.getTenantList((data)=>{ tenantArray.value = data })
    apiList.getAppList((data)=>{appArray.value = data})
    apiList.getChainList((res)=>{chainList.value = res})

    const getList = (e)=>{
        let obj_ = {
            current: page.currentPage,
            size: page.pageSize
        }
        if(e && e !== 1){
            obj_ = Object.assign({}, obj_, e)
        }
        listLoading.value = true
        Api_.cpmList(obj_).then(res=>{
            if(res.code === 0){
                listLoading.value = false
                list.value = res.data.records
                page.total = res.data.total
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
    }
    const rowUpdate = (row,index,done) => {
        Api_.cpmEdit(row).then(res=>{
            if(res.code === 0){
                getList()
                ElNotification.success('修改成功')
            }else{
                getList()
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
        done()

    }
    const rowDel = (row)=>{
        ElMessageBox.confirm( $t("alert.selEnter"), $t("tyle_.tits"), {
            confirmButtonText: $t("button.enter"),
            cancelButtonText: $t("button.close"),
            type: 'warning',
        }).then(()=>{
            Api_.cpmDel(row.id).then(res=>{
                if(res.code ===0 ){
                    getList(1)
                    ElNotification({
                        message: $t("alert.succDel"),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                } 
            })
        })

    }
    const saveFun =(row,done)=>{
        Api_.cpmAdd(row).then(res=>{
            if(res.code === 0){
                getList()
                ElNotification.success('添加成功')
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
        done()
    }
    const sizeChange = (pageSize)=>{ 
        page.pageSize = pageSize;
        getList(1)
    }
    const currentChange = (currentPage)=>{ 
        page.currentPage = currentPage;
        getList(1)
    }
    const searchChange = (form, done) => {
        searchForm.value = form;
        page.currentPage = 1;
        getList(searchForm.value);
        done();
    }
    getList(1)
</script>
<style lang="scss" scoped>
    :deep(.avue-crud__dialog__menu .el-dialog__close){
        display: none;
    }
</style>

